/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './pick-and-ban.scss';
import { SectionHeader } from '../../modules/common/components/section-header';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Button } from 'react-bootstrap';

const HSRPickBan: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page pvp'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Draft tool</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_draft.png"
            alt="Draft"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Draft tool</h1>
          <h2>
            A Draft tool used in Memory of Chaos PVP in Honkai: Star Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Announcement" />
        <StaticImage
          src="../../images/starrail/generic/drafttool.webp"
          alt="Draft"
        />
        <p>
          The Draft Tool has been moved to an outside platform and you can find
          it under the link below.
        </p>
        <OutboundLink href="https://starrailpb.dev/solo/game" target="_blank">
          <Button variant="primary">PVP Draft Tool</Button>
        </OutboundLink>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default HSRPickBan;

export const Head: React.FC = () => (
  <Seo
    title="Draft tool | Honkai: Star Rail | Prydwen Institute"
    description="A Draft tool used in Memory of Chaos PVP in Honkai: Star Rail."
  />
);
